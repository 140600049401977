import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import { jwtDecode } from 'jwt-decode';
import './Account.css';
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import logo from '../../Assets/embed_view_logo.png'; 
import { FcGoogle } from "react-icons/fc";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';


const Account = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [isRegister, setIsRegister] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false); // State to handle modal visibility
  const [isResettingPassword, setIsResettingPassword] = useState(false); // State to handle OTP and password reset
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(''); // State to store error message
  const [success, setSuccess] = useState(''); // State to store success message
      
      // Scroll to top when the component mounts
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);  

      useEffect(() => {
        // Check if the user is already logged in
        const email = localStorage.getItem('email');
        const username = localStorage.getItem('username');

        if (email && username) {
            // Redirect to the profile page if the user is logged in
            navigate('/profile_google');
        }
      }, [navigate]);

      // Check if the user is already logged in
      useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
          // If token exists, navigate to profile directly
          navigate('/profile');
        }
      }, [navigate]);

    // Validate email (must be @gmail.com or @yahoo.com)
      const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@(gmail|yahoo)\.com$/;
        return emailRegex.test(email);
      };
        
      // constant for registration
      // Validate password
      const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
        return passwordRegex.test(password);
      };

      // Handle registration form submission
      const handleRegister = async (e) => {
        e.preventDefault();

        // Clear any previous messages
        setError('');
        setSuccess('');

        // Validate input fields
        if (!username || !email) {
          setError('Username and email are required.');
          return;
        }

        if (!validateEmail(email)) {
          setError('Email must be a @gmail.com or @yahoo.com address.');
          return;
        }

        if (!validatePassword(password)) {
          setError('Password must be at least 8 characters long, contain an uppercase letter, a number, and a special character.');
          return;
        }

        // Proceed with registration if validation passes
        try {
          const response = await axios.post('http://54.176.39.98:8000/api/register/', {
            username,
            email,
            password,
          });
          if (response.status === 201) {
            setSuccess('Registration successful!');
            setError(''); // Clear error message if registration is successful
            setIsRegister(false); // Optionally switch to login form
          }
        } catch (error) {
          if (error.response && error.response.data && error.response.data.error) {
            setError(error.response.data.error); // Set the error message from backend
            setSuccess(''); // Clear success message if there's an error
          } else {
            setError('This username or email is already registered. Please try again.');
            setSuccess(''); // Clear success message if there's an error
          }
        }
      };

      // constant for login

      // Handle login form submission with validation
      const handleLogin = async (e) => {
        e.preventDefault();

        // Clear any previous error messages
        setError('');

        // Validate email and password
        if (!validateEmail(email)) {
          setError('Please enter a valid email address.');
          return;
        }

        if (!password) {
          setError('Password is required.');
          return;
        }

        // Proceed with login if validation passes
        try {
          const response = await axios.post('http://54.176.39.98:8000/api/login/', {
            email,
            password
          }, {
            headers: { 'Content-Type': 'application/json' }
          });

          // Save the access token and user details
          localStorage.setItem('access_token', response.data.access);
          localStorage.setItem('username', response.data.username); // Store username
          localStorage.setItem('email', response.data.email);       // Store email

          // Redirect to the profile page
          navigate('/profile');
        } catch (error) {
          if (error.response && error.response.data && error.response.data.error) {
            setError(error.response.data.error); // Display the error message from backend
          } else {
            setError('Login failed. Please check your credentials.');
          }
        }
      };

      // constant for register and login
        const toggleForm = () => {
          setIsRegister(!isRegister);
        };

        // constant for forgot password

        // Handle the "Forgot Password?" link click
        const handleForgotPassword = () => {
          setShowForgotPasswordModal(true); // Open the forgot password modal
        };
          
        // Handle the "Send OTP" button click

        // Handle "Send OTP" button click with validation
        const handleSendOtp = async () => {
          // Clear any previous error messages
          setError('');

          // Validate email before sending OTP
          if (!validateEmail(email)) {
            setError('Please enter a valid email address.');
            return;
          }

          try {
            await axios.post('http://54.176.39.98:8000/api/password-reset-request/', { email });
            setIsResettingPassword(true);
          } catch (error) {
            console.error('Error sending OTP:', error);
            setError('Failed to send OTP. Please try again.');
          }
        };

        // Handle the "Reset Password" button click
    
        // Handle "Reset Password" button click with validation
        const handleResetPassword = async () => {
          // Clear any previous error messages
          setError('');

          // Validate the input fields before resetting the password
          if (!otp) {
            setError('OTP is required.');
            return;
          }

          if (!validatePassword(newPassword)) {
            setError('Password must be at least 8 characters.');
            return;
          }

          if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
          }

          try {
            const response = await axios.post('http://54.176.39.98:8000/api/password-reset-confirm/', { email, otp, new_password: newPassword, confirm_password: confirmPassword });
            if (response.status === 200) {
              console.log('Password reset successful');
              window.location.href = '/account'; // Redirect to the account page
            }
          } catch (error) {
            console.error('Error resetting password:', error);
            setError('Failed to reset password. Please check your details and try again.');
          }
        };

        // Google login handler
        const handleGoogleLoginSuccess = async (response) => {
          try {
            // Send the Google token to the backend
            const backendResponse = await axios.post('http://54.176.39.98:8000/api/google-login/', {
              token: response.credential
            });

            console.log('Backend response:', backendResponse); // Debug response
              // Check for validation errors
              if (backendResponse.status === 200) {
                // Store user information from the backend response
                localStorage.setItem('email', backendResponse.data.email);
                localStorage.setItem('username', backendResponse.data.username);
    
                // Redirect to the profile page
                navigate('/profile_google');
              } else {
                // Handle validation errors
                alert(backendResponse.data.error || 'An error occurred during login.');
              }
          } catch (error) {
            console.error('Error logging in with Google:', error);
            alert('An error occurred during login.');
          }
        };

        // Google login error handler

          const handleGoogleLoginError = () => {
            console.error('Google login error');
          };



        //   complete form page
          return (
            <GoogleOAuthProvider clientId="620732210501-q82u8n543egf65g5625vfkbmrcn2i0to.apps.googleusercontent.com">
            <div className={`wrapper ${isRegister ? 'active' : ''}`}>
            <span className="bg-animate"></span>
            <span className="bg-animate2"></span>
            {/* Login Form */}
            <div className="form-box login">
              <img className="animation" style={{ "--i": 0, "--j": 21 }} src={logo} alt="Logo"/>
              <h2 className="animation" style={{ "--i": 0, "--j": 21 }}>Login</h2>       
              <form onSubmit={handleLogin}>
                <div className="input-box animation" style={{ "--i": 1, "--j": 22 }}>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label>Email</label>
                  <MdEmail className="icon" />
                </div>

                <div className="input-box animation" style={{ "--i": 2, "--j": 23 }}>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label>Password</label>
                  <FaLock className="icon" />
                </div>

                {/* Display error message if validation or login fails */}
                {error && (
                  <p className="message" style={{ color: 'red', fontSize: '14px', marginTop: '10px' }}>
                    {error}
                  </p>
                )}

                <div className="remember-forgot animation" style={{ "--i": 3, "--j": 24 }}>
                  <span className='link' onClick={handleForgotPassword}>Forgot Password?</span>
                </div>

                <button type="submit" className="button animation" style={{ "--i": 3, "--j": 24 }}>
                  Login
                </button>

                <div className="logreg-link animation" style={{ "--i": 4, "--j": 25 }}>
                  <p>Don't have an account? <span onClick={toggleForm} className="register-link">Register</span></p>
                </div>

                <div className="logreg-link animation" style={{ "--i": 4, "--j": 25 }}>
                  <p>Or Sign in with</p><br />
                  <GoogleLogin
                    clientId="620732210501-q82u8n543egf65g5625vfkbmrcn2i0to.apps.googleusercontent.com"
                    buttonText="Login with Google"
                    onSuccess={handleGoogleLoginSuccess}
                    onError={handleGoogleLoginError}
                    cookiePolicy={'single_host_origin'}
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <FcGoogle className="icon" /> Google
                      </button>
                    )}
                  />
                </div>    
              </form>
            </div>

            {/* Login Info */}
            <div className="info-text login">
                {/* <img className="animation" style={{ "--i": 5, "--j": 26 }} src={logo} alt="logo" /> */}
                <h2 className="animation" style={{ "--i": 0, "--j": 20 }}>Welcome!</h2>
                <p className="animation" style={{ "--i": 1, "--j": 21 }}>Embed View Technology specializes in providing cutting-edge hardware, software and IoT Solution's </p>
            </div>

            {/* Register Form */}
            <div className="form-box register">
                  <img className="animation" style={{ "--i": 17, "--j": 0 }} src={logo} alt="Logo"/>
                  <h2 className="animation" style={{ "--i": 17, "--j": 0 }}>Register</h2>
                  <form onSubmit={handleRegister}>
                <div className="input-box animation" style={{ "--i": 18, "--j": 1 }}>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <label>Username</label>
                  <FaUser className="icon" />
                </div>

                <div className="input-box animation" style={{ "--i": 19, "--j": 2 }}>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label>Email</label>
                  <MdEmail className="icon" />
                </div>

                <div className="input-box animation" style={{ "--i": 20, "--j": 3 }}>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label>Password</label>
                  <FaLock className="icon" />
                </div>

                {/* Display success or error message */}
                {(success || error) && (
                  <p
                    className="message"
                    style={{
                      color: success ? 'green' : 'red',
                      fontSize: '14px',
                      marginTop: '10px',
                    }}
                  >
                    {success || error}
                  </p>
                )}

                <button
                  type="submit"
                  className="button animation"
                  style={{ "--i": 21, "--j": 4 }}
                >
                  Register
                </button>

                <div className="logreg-link animation" style={{ "--i": 22, "--j": 5 }}>
                  <p>
                    Already have an account?{' '}
                    <span onClick={toggleForm} className="login-link">
                      Login
                    </span>
                  </p>
                </div>
              </form>
              </div>

              {/* Register Info */}
              <div className="info-text register">
                  <h2 className="animation" style={{ "--i": 17, "--j": 0 }}>Welcome!</h2>
                  <p className="animation" style={{ "--i": 18, "--j": 1 }}>The "Embed View Technology specializes in providing cutting-edge hardware, software and IoT Solution's "</p>
              </div>

              {/* Forgot Password Modal and confirmation password modal */}
              {/* // Forgot Password Modal */}
                {showForgotPasswordModal && (
                  <div className="modal">
                    <div className="modal-content">
                      {/* Error message display */}
                      {error && (
                        <p className="message" style={{ color: 'red', fontSize: '14px', marginTop: '10px' }}>
                          {error}
                        </p>
                      )}

                      {!isResettingPassword ? (
                        <>
                          <h2>Reset Your Password</h2>
                          <p>Enter your email address to receive an OTP:</p>
                          <input
                            type="email"
                            placeholder="Your Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <button type="button" className="act_button" onClick={handleSendOtp}>Send OTP</button>
                        </>
                      ) : (
                        <>
                          <h2>Reset Your Password</h2>
                          <p>Enter the OTP and new password:</p>
                          <input
                            type="email"
                            placeholder="Your Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <input
                            type="text"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                          />
                          <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                          />
                          <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                          <button type="button" className="act_button" onClick={handleResetPassword}>Reset Password</button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              </GoogleOAuthProvider>
            );
  };

export default Account;
