import React, { useState, useEffect } from 'react';
import './EmbeddedSoftwareEngineer.css';

const EmbeddedSoftwareEngineer = () => {
  

  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
    // Email validation function
  const [responseMessage, setResponseMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [messageColor, setMessageColor] = useState('green'); // State for message color
  const [phone, setPhone] = useState(''); // State for phone number
  const [isLoading, setIsLoading] = useState(false); // New loading state

    // Auto-hide response message after a few seconds
    useEffect(() => {
      if (responseMessage) {
        const timer = setTimeout(() => {
          setResponseMessage('');
        }, 3000); // Hide message after 3000 milliseconds (3 seconds)
  
        return () => clearTimeout(timer); // Clean up the timer
      }
    }, [responseMessage]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading state

    const form = event.target;
    const formData = new FormData(form);
    
    // Extract form data
    let fullName = formData.get('full_name');
    const email = formData.get('email');
    // const phone = formData.get('phone');
    const coverLetter = formData.get('cover_letter');
    // const resume = formData.get('resume');

    // Clear previous error messages
    setResponseMessage('');
    setFormErrors({});

    // Validate the fields
    const errors = {};
    
    if (!validateFullName(fullName)) {
      errors.fullName = 'Full name must start with a capital letter and contain only letters and spaces.';
    }

    if (!validateEmail(email)) {
      errors.email = 'Invalid email format."@gmail.com" or "@yahoo.com".';
    }

    if (!validatePhone(phone)) {
      errors.phone = 'Must be an Indian number (10 digits starting with 6, 7, 8, or 9).';
    }

    if (!validateCoverLetter(coverLetter)) {
      errors.coverLetter = 'Cover letter must be at least 20 characters long.';
    }

    // if (!validateResume(resume)) {
    //   errors.resume = 'Resume must be a PDF file and less than 2MB.';
    // }

    // If there are any errors, stop form submission and display errors
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setMessageColor('red'); // Set color to red for errors
      setIsLoading(false); // Reset loading state
      return;
    }

    // Proceed with form submission if validation passes
    try {
      const response = await fetch('http://127.0.0.1:8000/api/embedded_software/', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        setResponseMessage(responseData.message || 'The application has been successfully submitted.');
        setMessageColor('green'); // Set color to green for success
        // Reset the phone number after successful submission
        setPhone(''); // Clear the phone input
        form.reset(); // Reset the form fields after successful submission
      } else {
            const errorData = await response.json();
            // Check for specific error message regarding existing email
            if (response.status === 409) { // Assuming 409 for email conflict
              setResponseMessage('Error: Email already exists.');
          } 
          else {
              setResponseMessage('Error: ' + JSON.stringify(errorData));
          }
          setMessageColor('red');
      }
    } catch (error) {
      setResponseMessage('An error occurred: ' + error.message);
      setMessageColor('red'); // Set color to red for catch errors
    }finally {
      setIsLoading(false); // Always reset loading state
    }
  };

  const validateFullName = (name) => {
    // Check if the full name starts with a capital letter and only contains letters and spaces
    const fullNameRegex = /^[A-Z][a-z]*(?:\s[A-Z][a-z]*)*$/;
    return fullNameRegex.test(name.trim());
  };
  const validateEmail = (email) => {
    const allowedDomains = ['gmail.com', 'yahoo.com'];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!emailRegex.test(email)) {
      return false; // Invalid email format
    }
  
    // Extract the domain from the email and check if it's allowed
    const emailDomain = email.split('@')[1];
    return allowedDomains.includes(emailDomain);
  };
  

  const validatePhone = (phone) => {
    // Check if the phone number is a valid Indian phone number (10 digits)
    const phoneRegex = /^(?:[6789]\d{9})$/; // Starts with 7, 8, or 9 and followed by 9 digits
    return phoneRegex.test(phone);
  };

  const validateCoverLetter = (coverLetter) => {
    return coverLetter.trim().length >= 20;
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Allow only digits
    if (value.length <= 10) {
      setPhone(value);
    }
  };

  // const validateResume = (resume) => {
  //   const validTypes = ['application/pdf'];
  //   const maxSize = 2 * 1024 * 1024; // 2MB

  //   if (resume && validTypes.includes(resume.type) && resume.size <= maxSize) {
  //     return true;
  //   }
  //   return false;
  // };
  return (
    // embedded software engineer page
<div className="job-description-container">
      {/* embedded software engineer description */}
      <div className="job-details">
        <h2>Embedded Software Engineer</h2>
        {/* embedded software engineer responsibilities description */}
        <h3>Responsibilities</h3>
        <div className='job-description'>
          <p>Hardware: Schematic design, PCB design (basic knowledge), Bill of Material and component selection, Use of H/w and S/w debuggers, Testing and integration.</p>
          <p>Strong C programming knowledge.</p>
          <p>Interfaces & Protocols: RS232, RS485, CAN, I2C, SPI, UART, Modbus, BLE, Wi-Fi, LTE, Bootloader, Ethernet.</p>
          <p>Experience with one or more RTOS-Free RTOS, Zephyr preferred.</p>
          <p>Proficiency with electronics bench test equipment such as oscilloscopes, logic analyzers, etc.</p>
          <p>Experience developing low-level device drivers for communication protocols such as CAN (-FD), UART, RS232, RS485, Ethernet, TCP/IP, USB, SPI, and I2C.</p>
          <p>Autocar knowledge is an advantage.</p>
        </div>
        <h3>Required Education Skills</h3>
        {/* embedded software engineer education skills description */}
        <div className='job-description'>
          <p>Bachelor's degree in Electrical Engineering, Computer Science, or a related field. An advanced degree (Master's or higher) is a plus.</p>
          <p>Minimum of one year of hands-on experience in embedded software development, demonstrating a strong understanding of the software development lifecycle.</p>
          <p>Programming Languages C and C++ programming languages for embedded systems development.</p>
          <p>Real-Time Operating Systems (RTOS) Practical experience working with Real-Time Operating Systems and developing software for time-critical applications.</p>
          <p>Hardware-Software Integration Hands-on experience collaborating with hardware engineers to integrate software components seamlessly into embedded systems.</p>
          <p>Proficient in using version control systems (e.g., Git) for source code management and collaborative development.</p>
          <p>Adequate knowledge of reading schematics and data sheets for components with the ability to create clear and concise technical documents for design, implementation, and testing.</p>
          <p>Excellent verbal and written communication skills, with the ability to collaborate effectively within cross-functional teams.</p>
          <p>Problem-solving skills and the ability to troubleshoot complex issues in embedded software systems.</p>
          <p>A proactive learner with the ability to adapt to new technologies and methodologies in the dynamic field of embedded systems.</p>
          <p>A collaborative and team-oriented mindset, with the ability to work harmoniously within a diverse and multidisciplinary team environment.</p>
        </div>
        {/* embedded software engineer additional description */}
        <div className='job-description-info'>
            <p><b>Job Category:</b>Embedded Software Engineer</p>
            <p><b>Job Type:</b>Full Time</p>
            <p><b>Job Location:</b>Hyderabad</p>
        </div>
      </div>
      {/* embedded software engineer apply form */}
       {/* Application form */}
       <div className="apply-form">
        <h3>Apply for this position</h3>
        <form onSubmit={handleSubmit}>
          <label>Full Name *</label>
          <input type="text" name="full_name"/>
          {formErrors.fullName && <p className="error-message">{formErrors.fullName}</p>}
          
          <label>Email *</label>
          <input type="email" name="email"/>
          {formErrors.email && <p className="error-message">{formErrors.email}</p>}
          
          <label>Phone *</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '8px', color: '#000' }}>+91</span>
            <input
              type="tel"
              name="phone"
              value={phone}
              onChange={handlePhoneChange}
              maxLength={10}
              placeholder="Enter phone number"
              style={{ flex: 1,  border: '1px solid #ccc', borderRadius: '4px',marginTop: '15px' }} // Make input take remaining space
            />
          </div>
          {formErrors.phone && <p className="error-message">{formErrors.phone}</p>}
          
          
          <label>Cover Letter *</label>
          <textarea name="cover_letter" rows="4"></textarea>
          {formErrors.coverLetter && <p className="error-message">{formErrors.coverLetter}</p>}

          <label htmlFor="resume">Resume *</label>
          <input type="file" id="resume" name="resume"/>
          {formErrors.resume && <p className="error-message">{formErrors.resume}</p>}
          
          <button type="submit" disabled={isLoading}>Submit</button>
        </form>

         {/* Response message */}
         {isLoading ? (
          <div className="loading-message">
            <p>Please wait...</p>
          </div>
        ) : (
          responseMessage && (
            <div className="response-message" style={{ color: messageColor }}>
              <p>{responseMessage}</p>
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default EmbeddedSoftwareEngineer
